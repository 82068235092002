<template>
  <!-- 用户管理 -->
  <div>
    <div style="margin-bottom: 15px">
      <span>订单时间：</span>
      <el-date-picker
        @change="timeChange"
        v-model="value"
        type="daterange"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      <el-input
        v-model.trim="listQuery.keyWord"
        placeholder="请输入订单创建人"
        style="width: 250px; margin-left: 20px"
      ></el-input>
      <el-button type="primary" style="margin-left: 10px" @click="search"
        >搜索</el-button
      >
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" label="序号" width="100"></el-table-column>
      <el-table-column
        prop="orderTime"
        label="订单时间"
        width=""
      ></el-table-column>
      <el-table-column
        prop="totalMoney"
        label="订单金额（元）"
        width=""
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="totalNumber"
        label="产品数量（件）"
        width=""
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="orderCreateUser"
        label="订单创建人"
        width=""
        show-overflow-tooltip
      ></el-table-column>
      <!-- <el-table-column prop="shareUser" label="分享用户" width="" show-overflow-tooltip></el-table-column> -->
      <el-table-column
        prop="updateTime"
        label="操作"
        width=""
        show-overflow-tooltip
      >
        <template v-slot="scope">
          <el-button type="text" @click="viewClick(scope.row.orderId)"
            >订单详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="listQuery.page"
        :page-size="listQuery.size"
        layout="total, prev, pager, next, jumper"
        :total="listQuery.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { orderpage } from "../../api/index";
export default {
  data() {
    return {
      listQuery: {
        keyWord: "",
        page: 1,
        size: 10,
        total: 0,
        beginTime: "",
        endTime: "",
      },
      tableData: [],
      value: [],
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    timeChange(val) {
      if (val) {
        this.listQuery.beginTime = val[0] + " 00:00:00" || "";
        this.listQuery.endTime = val[1] + " 23:59:59" || "";
      } else {
        this.listQuery.beginTime = "";
        this.listQuery.endTime = "";
        this.value = [];
      }
    },
    search() {
      this.listQuery.page = 1;
      this.getTableData();
    },
    handleCurrentChange(page) {
      this.listQuery.page = page;
      this.getTableData();
    },
    getTableData() {
      orderpage(this.listQuery).then((res) => {
        let data = res.data || {};
        this.tableData = data.records || [];
        this.listQuery.total = data.total || 0;
      });
    },
    viewClick(orderId) {
      this.$router.push({
        name: "orderHistoryDetail",
        query: { orderId: orderId },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}
</style>
